import { Refresh, Delete as DeleteIcon } from '@material-ui/icons'
import {
  IconButton,
  Card,
  Typography,
  CardContent,
  CardActions,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  Divider,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import api from '../dataProvider'
import { useToasts } from 'react-toast-notifications'

export default function Groups() {
  const { addToast } = useToasts()

  const [groups, setGroups] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openAddGroupDialog, setOpenAddGroupDialog] = useState(false)
  const [langDeleteObj, setLangDeleteObj] = useState(
    {} as { group: any; language: string },
  )
  const [langAddObj, setLangAddObj] = useState(
    {} as { group: any; language: string },
  )

  useEffect(() => {
    api.getGroups().then((groups) => setGroups(groups))
  }, [refresh])

  const deleteGroupLanguage = async () => {
    try {
      await api.updateGroup(langDeleteObj.group.code, {
        allowedLanguages: langDeleteObj.group.allowedLanguages.filter(
          (language: string) => language !== langDeleteObj.language,
        ),
      })
      setRefresh(!refresh)
      setOpenDeleteDialog(false)
      addToast(
        `Language ${langDeleteObj.language} deleted from group ${langDeleteObj.group.name} successfully`,
        {
          appearance: 'success',
        },
      )
    } catch (err) {
      console.log(err)
      addToast(
        `Failed to delete language ${langDeleteObj.language} from group ${langDeleteObj.group.name}`,
        {
          appearance: 'error',
        },
      )
    } finally {
      setLangDeleteObj({} as { group: any; language: string })
    }
  }

  const addGroupLanguage = async () => {
    try {
      await api.updateGroup(langAddObj.group.code, {
        allowedLanguages: [
          ...langAddObj.group.allowedLanguages,
          langAddObj.language,
        ],
      })
      setRefresh(!refresh)
      addToast(
        `Language ${langAddObj.language} added to group ${langAddObj.group.name} successfully`,
        {
          appearance: 'success',
        },
      )
    } catch (err) {
      console.log(err)
      addToast(
        `Failed to add language ${langAddObj.language} to group ${langAddObj.group.name}`,
        {
          appearance: 'error',
        },
      )
    } finally {
      setLangAddObj({} as { group: any; language: string })
    }
  }

  const DeleteConfirmationDialog = () => (
    <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
      <DialogTitle>
        Delete {langDeleteObj.language} from {langDeleteObj.group.name}
      </DialogTitle>
      <DialogContent>
        Are you sure you want to delete{' '}
        <strong>
          {langDeleteObj.language} from {langDeleteObj.group.name}
        </strong>
        ?
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={deleteGroupLanguage}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={() => setOpenDeleteDialog(false)}
          >
            No
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <h1>Groups</h1>
          <IconButton onClick={() => setRefresh(!refresh)}>
            <Refresh />
          </IconButton>
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: 'auto', marginBottom: 'auto' }}
          onClick={() => setOpenAddGroupDialog(true)}
        >
          + New
        </Button>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {groups.map((group: any) => {
          return (
            <Card
              style={{
                margin: 20,
                width: 350,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardContent>
                <Typography variant="h6" component="h2">
                  {group.name}
                </Typography>
                <Typography color="textSecondary">{group.code}</Typography>
                <br />
                <Typography>
                  <strong>Languages</strong>
                </Typography>
                <List>
                  {group.allowedLanguages.map((language: string) => (
                    <>
                      <ListItem>
                        <ListItemText primary={language} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              setLangDeleteObj({
                                group: group,
                                language: language,
                              })
                              setOpenDeleteDialog(true)
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider variant="middle" component="li" />
                    </>
                  ))}
                </List>
              </CardContent>
              <CardActions
                style={{ margin: 10, marginTop: 'auto' }}
                disableSpacing
              >
                <form style={{ width: '100%' }} noValidate autoComplete="off">
                  <TextField
                    style={{ width: '100%' }}
                    id="add-language"
                    label="Add Language"
                    onChange={(e) =>
                      setLangAddObj({ group: group, language: e.target.value })
                    }
                  />
                </form>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addGroupLanguage}
                >
                  Add
                </Button>
              </CardActions>
            </Card>
          )
        })}
      </div>
      {openDeleteDialog && <DeleteConfirmationDialog />}
      {openAddGroupDialog && (
        <AddGroupDialog
          openAddGroupDialog={openAddGroupDialog}
          setOpenAddGroupDialog={setOpenAddGroupDialog}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      )}
    </React.Fragment>
  )
}

function AddGroupDialog({
  openAddGroupDialog,
  setOpenAddGroupDialog,
  refresh,
  setRefresh,
}: any) {
  const { addToast } = useToasts()

  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [languages, setLanguages] = useState(['english', 'english-mandarin'])
  const [langAdd, setLangAdd] = useState('')

  const addGroup = async () => {
    try {
      await api.addGroup({
        name: name,
        code: code,
        allowedLanguages: languages,
      })
      setRefresh(!refresh)
      addToast(`Group ${name} added successfully`, {
        appearance: 'success',
      })
    } catch (err) {
      console.log(err)
      addToast(`Failed to add Group ${name}`, {
        appearance: 'error',
      })
    } finally {
      setOpenAddGroupDialog(false)
    }
  }

  return (
    <Dialog
      open={openAddGroupDialog}
      onClose={() => setOpenAddGroupDialog(false)}
    >
      <DialogTitle>Add New Group</DialogTitle>
      <DialogContent>
        <form style={{ width: '95%' }} noValidate autoComplete="off">
          <TextField
            style={{ width: '100%' }}
            id="new-group-name"
            label="Group Name"
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            style={{ width: '100%' }}
            id="new-group-code"
            label="Group Code"
            onChange={(e) => setCode(e.target.value)}
          />
        </form>
        <br />
        <Typography>
          <strong>Languages</strong>
        </Typography>
        <List>
          {languages.map((language: string) => (
            <>
              <ListItem>
                <ListItemText primary={language} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      setLanguages(
                        languages.filter((lang: string) => lang !== language),
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="middle" component="li" />
            </>
          ))}
        </List>
        <br />
        <form
          style={{ width: '100%', display: 'flex' }}
          noValidate
          autoComplete="off"
        >
          <TextField
            style={{ width: '100%', marginRight: 5 }}
            id="add-language"
            label="Add Language"
            onChange={(e) => setLangAdd(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setLanguages([...languages, langAdd])}
          >
            Add
          </Button>
        </form>

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            onClick={addGroup}
          >
            SAVE
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
