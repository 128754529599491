import React, { useEffect, useState } from 'react'
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from 'react-router-dom'

import Login from './Login'
import Dashboard from './Dashboard'

import { UserContext } from '../contexts'
import api from '../dataProvider'
import { IUserContext } from '../types'

function PrivateRoute({ User, setUser, children, ...rest }: any) {
  useEffect(() => {
    api
      .validateJWT()
      .then((user: IUserContext) => {
        setUser(user)
      })
      .catch((err) => {
        setUser(null)
      })
  }, [setUser])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        User ? (
          <UserContext.Provider value={User}>{children}</UserContext.Provider>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }, // page from which login page was prompted
            }}
          />
        )
      }
    />
  )
}

export default function App({ jwtUser }: any) {
  const [User, setUser] = useState(jwtUser as IUserContext | null)

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/login">
            <Login setUser={setUser} />
          </Route>
          <PrivateRoute path="/" User={User} setUser={setUser}>
            <Dashboard setUser={setUser} />
          </PrivateRoute>
        </Switch>
      </Router>
    </React.Fragment>
  )
}
