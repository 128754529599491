import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import App from './components/App'
import { ToastProvider } from 'react-toast-notifications'

import api from './dataProvider'
import { IUserContext } from './types'

api
  .validateJWT()
  .then((user: IUserContext) => {
    ReactDOM.render(
      <React.StrictMode>
        <ToastProvider placement="bottom-right" autoDismiss>
          <App jwtUser={user} />
        </ToastProvider>
      </React.StrictMode>,
      document.getElementById('root'),
    )
  })
  .catch((err) => {
    ReactDOM.render(
      <React.StrictMode>
        <ToastProvider placement="bottom-right" autoDismiss>
          <App jwtUser={null} />
        </ToastProvider>
      </React.StrictMode>,
      document.getElementById('root'),
    )
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
