import axios from 'axios'
import jwtdecode from 'jwt-decode'
import {
  UpdateUserParams,
  IConversationParams,
  IUserContext,
  IUserParams,
} from './types'

class DataProvider {
  private apiUrl = process.env.REACT_APP_API_HOST
  private api = axios.create({
    baseURL: this.apiUrl,
  })

  private readonly roles = ['admin', 'user', 'group-admin']
  private readonly statuses = [
    'in-lobby',
    'in-progress',
    'waiting-upload',
    'pending',
    'processing',
    'done',
    'waiting-manual-transcription',
    'error',
    'unknown',
  ]
  private readonly languages = [
    'english',
    'mandarin',
    'malay',
    'english-mandarin',
    'english-malay',
    'capelle-english',
    'capelle-english-mandarin',
    'manual-english',
    'manual-english-mandarin',
    'viriya-english',
    'viriya-english-test',
    'abax-s2t',
    'cc-s2t'
  ]

  constructor() {
    this.api.interceptors.request.use((config) => {
      config.headers['Authorization'] =
        'Bearer ' + localStorage.getItem('accessToken')
      return config
    })
  }

  get Roles() {
    return this.roles
  }

  get Statuses() {
    return this.statuses
  }

  get Languages() {
    return this.languages
  }

  async validateJWT(): Promise<IUserContext> {
    try {
      const accessToken = localStorage.getItem('accessToken')
      console.log('Access Token')
      console.log(accessToken)

      let decoded: any = null
      if (accessToken) {
        decoded = jwtdecode(accessToken)
      } else {
        throw new Error('401: Unauthorized')
      }
      console.log(decoded)

      const conversations = await this.getConversations({ limit: 0 })
      return {
        email: decoded.email,
        name: decoded.name,
        gatewayRole: decoded.role,
      }
    } catch (err) {
      console.log(err)
      return Promise.reject(err)
    }
  }

  async login(email: string, password: string): Promise<any> {
    try {
      const res = await this.api.post('/auth/login', {
        email: email,
        password: password,
      })

      console.log(res.data.accessToken)
      const decoded: any = jwtdecode(res.data.accessToken)

      console.log('in login function')
      console.log(decoded)
      console.log(res.data.role)
      if (res.data.role !== 'admin') throw new Error()

      this.api.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${res.data.accessToken}`

      localStorage.setItem('accessToken', res.data.accessToken)

      return {
        email: decoded.email,
        name: decoded.name,
        gatewayRole: decoded.role,
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async logout() {
    delete this.api.defaults.headers.common['Authorization']
    localStorage.removeItem('accessToken')
  }

  async getConversations(params: IConversationParams): Promise<any> {
    console.log(params)
    try {
      const res = await this.api.get('/summary/conversations', {
        params: { ...params },
      })
      console.log(res.data)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getUsers(params: IUserParams): Promise<any> {
    try {
      const res = await this.api.get('/summary/users', {
        params: { ...params },
      })
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async updateUser(params: UpdateUserParams): Promise<any> {
    try {
      const res = await this.api.patch('/summary/users', {
        ...params,
      })
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async deleteConversation(id: string): Promise<any> {
    try {
      const res = await this.api.delete(`/summary/conversations/${id}`)
      return res
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getSentences(id: string): Promise<any> {
    try {
      const res = await this.api.get(`/summary/conversations/${id}/sentences`)
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async exportConversation(id: string): Promise<any> {
    try {
      const res = await this.api.get(`/summary/conversations/${id}/export`, {
        responseType: 'blob',
      })
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getGroups(): Promise<any> {
    try {
      const res = await this.api.get('/groups')
      return res.data
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async updateUsersGroup(userIds: string[], group: string): Promise<any> {
    try {
      await this.api.patch('/summary/users/group', {
        ids: userIds,
        group: group,
      })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async uploadTranscript(conversation: any, textgrid: File) {
    const formData = new FormData()
    formData.append('transcript', textgrid)
    await this.api.post(
      `/conversations/${conversation._id}/uploadTranscript`,
      formData,
    )
    if (conversation.status === 'waiting-manual-transcription') {
      await this.api.patch(
        `/conversations/${conversation._id}/completeTranscript`,
      )
    }
  }

  async updateGroup(code: string, payload: any): Promise<any> {
    try {
      const updatedGroup = await this.api.patch(`groups/${code}`, payload)
      return updatedGroup
    } catch (err) {
      Promise.reject(err)
    }
  }

  async addGroup(payload: {
    name: string
    code: string
    allowedLanguages?: string[]
  }): Promise<any> {
    try {
      const newGroup = await this.api.post(`groups`, payload)
      return newGroup
    } catch (err) {
      Promise.reject(err)
    }
  }

  async resubmit(conversation: any) {
    await this.api.post(`/conversations/${conversation._id}/resubmit`)
  }

  async massRegister(csv: File, group: string): Promise<any> {
    const formData = new FormData()
    formData.append('file', csv, csv.name)
    formData.append('group', group)
    return this.api.post(`/summary/users/massRegister`, formData)
  }
}

export default new DataProvider()
