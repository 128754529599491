export function processName(name: string): string {
  // for capitalizing names
  return name
    .split(' ')
    .map((s: string) => {
      s = s.trim()
      return s.charAt(0).toUpperCase() + s.slice(1)
    })
    .join(' ')
}

export function displayDuration(duration: number): string {
  // for displaying audio duration as 'n h n m n s'
  duration = Math.round(duration * 10) / 10 // round duration to one decimal place
  if (duration === 0) return '0 s'
  let hours = Math.floor(duration / 3600)
  let minutes = Math.floor((duration - hours * 3600) / 60)
  let seconds = Math.round((duration - (hours * 3600 + minutes * 60)) * 10) / 10
  let durString = ''
  if (hours > 0) durString += hours + ' h '
  if (minutes > 0) durString += minutes + ' m '
  if (seconds > 0) durString += seconds + ' s '
  return durString
}

export function displayAudioTime(time: number): string {
  // for displaying audio start and end times (from sentences) as 'hh:mm:ss'
  time = Math.floor(time)
  let hours = Math.floor(time / 3600)
  let minutes = Math.floor((time - hours * 3600) / 60)
  let seconds = Math.floor(time - (hours * 3600 + minutes * 60))
  let audioTime = ''
  if (hours > 0) audioTime += hours < 10 ? '0' + hours + ':' : hours + ':'
  audioTime += minutes < 10 ? '0' + minutes + ':' : minutes + ':'
  audioTime += seconds < 10 ? '0' + seconds : seconds
  return audioTime
}
