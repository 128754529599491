import React, { useContext, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import GroupIcon from '@material-ui/icons/Group'
import PersonIcon from '@material-ui/icons/Person'
import { Button, IconButton, Menu, MenuItem } from '@material-ui/core'
import { Route, Switch, useHistory } from 'react-router-dom'
import { ExitToApp } from '@material-ui/icons'
import Conversations from './Conversations'
import Users from './Users'

import api from '../dataProvider'

import { UserContext } from '../contexts'
import Groups from './Groups'

const drawerWidth = 200

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
}))

export default function Dashboard(props: any) {
  const user = useContext(UserContext)

  const history = useHistory()

  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const drawerItems = [
    {
      name: 'Conversations',
      icon: <RecordVoiceOverIcon />,
      to: '/',
      onClick: () => history.push('/'),
    },
    {
      name: 'Users',
      icon: <PersonIcon />,
      to: '/users',
      onClick: () => history.push('/users'),
    },
    {
      name: 'Groups',
      icon: <GroupIcon />,
      to: '/groups',
      onClick: () => history.push('/groups'),
    },
  ]

  const [anchorEl, setAnchorEl] = useState(null)
  const handleUserMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleUserMenuClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    api.logout()
    props.setUser(null)
  }

  const UserMenu = ( // displays email and logout button
    <div style={{ marginLeft: 'auto' }}>
      <Button
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleUserMenuClick}
      >
        {user !== null && user.name}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleUserMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem>{user !== null && user.email}</MenuItem>
        <MenuItem onClick={handleLogout}>
          <ExitToApp style={{ margin: 5, marginRight: 10 }} />
          <strong>Logout</strong>
        </MenuItem>
      </Menu>
    </div>
  )

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="drawer button"
              onClick={() => setOpen(!open)}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {UserMenu}
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <List>
              {drawerItems.map((item) => (
                <ListItem button key={item.name} onClick={item.onClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <Toolbar />
          <Switch>
            <Route exact path="/">
              <Conversations />
            </Route>
            <Route exact path="/users">
              <Users />
            </Route>
            <Route exact path="/groups">
              <Groups />
            </Route>
          </Switch>
        </main>
      </div>
    </React.Fragment>
  )
}
