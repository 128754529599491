import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import api from '../dataProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '90%',
    },
  },
  loginForm: {
    padding: 20,
    margin: 'auto',
  },
  loginFormTitle: {
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    color: 'white',
  },
  loginTypeButton: {
    marginLeft: 'auto',
    marginRight: 20,
  },
  submitButton: {
    marginLeft: 'auto',
    marginRight: 20,
  },
}))

export default function Login(props: any) {
  const classes = useStyles()
  const theme = useTheme()

  const history = useHistory()
  const location = useLocation()
  const { from } = (location.state || { from: { pathname: '/' } }) as any

  const [incorrectCred, setIncorrectCred] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const login = async (email: string, password: string) => {
    try {
      const user = await api.login(email, password)
      setIncorrectCred(false)
      props.setUser(user)
      history.replace(from)
    } catch (err) {
      setIncorrectCred(true)
      props.setUser(null)
    }
  }

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: any) => {
    setPassword(event.target.value)
  }

  return (
    <Container>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh', minWidth: 300 }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Paper component="div">
            <Box bgcolor={theme.palette.primary.main}>
              <Box display="flex" className={classes.loginFormTitle}>
                <Typography variant="h6">Login</Typography>
              </Box>
            </Box>

            {/* to notify of failed login */}
            {incorrectCred && (
              <Box display="flex" alignItems="center">
                <ErrorOutlineIcon
                  fontSize="small"
                  style={{ marginLeft: 20, marginTop: 10 }}
                  color="secondary"
                />
                <Typography
                  color="secondary"
                  style={{ marginLeft: 10, marginTop: 10 }}
                >
                  E-mail/Password is incorrect
                </Typography>
              </Box>
            )}

            <Box className={classes.loginForm}>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  id="email"
                  autoComplete="current-email"
                  label="E-mail"
                  onChange={handleEmailChange}
                />
                <TextField
                  id="password"
                  type="password"
                  label="Password"
                  onChange={handlePasswordChange}
                  autoComplete="current-password"
                />
                <Box display="flex" style={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    className={classes.submitButton}
                    color="primary"
                    onClick={() => login(email, password)}
                  >
                    Login
                  </Button>
                </Box>
              </form>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
